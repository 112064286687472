import { createHashRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { ErrorBoundary } from '../components/ErrorBoundary';
import AppLayout from '../layout/AppLayout';
import { suspendPageLoad } from './suspendPageLoad';
import { detectFeatureToggles } from '../configuration/setup/featureToggles';
import withFeatureToggle from '../hoc/withFeatureToggle';

// Lazy load pages for better performance and automatically split the bundle accordingly
const Intro = suspendPageLoad(() => import('../pages/Intro'));
const ConfLogic = suspendPageLoad(() => import('../pages/ConfLogicContainer'));
const Templates = suspendPageLoad(() => import('../pages/TemplatesContainer'));
// const UserSidebarLoader = suspendPageLoad(() => import('../features/users/userSidebar/UserSidebarLoader'));

export const DEFAULT_ROUTE = '/intro';
// export const ROUTE_MORE = '/more';
export const ROUTE_TEMPLATES = '/templates';
export const ROUTE_CONFLOGIC = '/conflogic';

export const routes = [DEFAULT_ROUTE, ROUTE_CONFLOGIC, ROUTE_TEMPLATES];
const { useConfLogic, useTemplates } = detectFeatureToggles();
const DefaultRoute = <Route path={DEFAULT_ROUTE} errorElement={<ErrorBoundary />} element={<Intro />} />;
const ConfLogicRoute = <Route path={ROUTE_CONFLOGIC} errorElement={<ErrorBoundary />} element={<ConfLogic />} />;
const TemplateRoute = <Route path={ROUTE_TEMPLATES} errorElement={<ErrorBoundary />} element={<Templates />} />;
export const router = createHashRouter(
    createRoutesFromElements(
        <Route path='/' element={<AppLayout />}>
            {DefaultRoute}
            {useConfLogic && ConfLogicRoute}
            {useTemplates && TemplateRoute}
        </Route>
    )
);
