import queryString from 'query-string';
import { getFromLocalStorage, saveToLocalStorage } from './localStorage';

export const ENFORCED_ENV = 'enforcedEnv';
export const ENFORCED_LOCALE = 'enforcedLocale';
export const MOCKED_TOKEN = 'mockedToken';
export const USE_VALID_TOKEN = 'useValidToken';
export const USE_CONFLOGIC = 'useConfLogic';
export const USE_TEMPLATES = 'useTemplates';
export const USE_TEMPLATES_REFRESH = 'useTemplatesRefresh';
export const detectFeatureToggles = () => {
    const detectToggle = (transform, name) => {
        const [_, searchParams = ''] = window.location.hash.split('?');
        const params = queryString.parse(searchParams);

        const toggleValue = params[name];
        const value = toggleValue ? saveToLocalStorage(name, toggleValue) : getFromLocalStorage(name);

        return value && transform(value);
    };

    const BooleanToggle = (value) => value === 'true' || value === '1';

    return {
        [ENFORCED_ENV]: detectToggle(String, 'ft_env'),
        [ENFORCED_LOCALE]: detectToggle(String, 'ft_enforceLocale'),
        [MOCKED_TOKEN]: detectToggle(String, 'ft_mockedToken'),
        [USE_VALID_TOKEN]: detectToggle(BooleanToggle, 'ft_useValidToken'),
        [USE_CONFLOGIC]: detectToggle(BooleanToggle, 'ft_confLogic'),
        [USE_TEMPLATES]: detectToggle(BooleanToggle, 'ft_templates'),
        [USE_TEMPLATES_REFRESH]: detectToggle(BooleanToggle, 'ft_templatesRefresh'),
    };
};
